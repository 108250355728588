import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import { useStaticQuery, graphql, Link } from 'gatsby';

import MiscWrapper from './misc.style';

import ChecklistImg from 'common/src/assets/image/hosting/Pages/Misc/Checklist-for-AI-Automation-v2.jpg';
import FourStep from 'common/src/assets/image/hosting/Pages/Misc/Ultimate-4-step-guide-v2.jpg';
import Checklist from 'common/src/assets/image/hosting/Pages/Misc/Cheat-Sheet-Banner.jpg';
import benifits from 'common/src/assets/image/hosting/Pages/Infographics/6 Must Have Benefits of AI Automation.jpg';

const MiscellaneousSection = ({ row, col, title, textArea, description }) => {
  return (
    <MiscWrapper>
      <Fade bottom cascade delay={5}>
        <Container>
          <Box {...row}>
            <Box {...col}>
              <Link to={`/miscellaneous/checklist-for-AI-automation`}>
                <Image src={ChecklistImg} alt="Checklist For AI Automation" />
                {/* <Heading {...title} content="Checklist For AI Automation" /> */}
                <Heading
                  {...description}
                  content={
                    'Is your AI automation use case a good idea? Find out with our checklist for self-evaluation.'
                  }
                />
              </Link>
            </Box>
            <Box {...col}>
              <Link
                to={`/miscellaneous/ultimate-4-step-guide-to-identify-ipa-use-cases`}
              >
                <Image
                  src={FourStep}
                  alt="Ultimate 4 Step Guide to Identify IPA use Cases"
                />
                {/* <Heading
                {...title}
                content="Ultimate 4 Step Guide to Identify IPA use Cases"
              /> */}
                <Heading
                  {...description}
                  content={
                    'Wondering how to pick the right IPA use case for your business? Download our free guide.'
                  }
                />
              </Link>
            </Box>
            <Box {...col}>
              <Link
                to={`/miscellaneous/cheat-sheet-for-developing-your-ipa-use-case`}
              >
                <Image
                  src={Checklist}
                  alt="cheat-sheet-for-developing-your-ipa-use-case"
                />
                {/* <Heading
                {...title}
                content="Ultimate 4 Step Guide to Identify IPA use Cases"
              /> */}
                <Heading
                  {...description}
                  content={
                    'No need for guesswork. With this cheat sheet, you can develop and refine your IPA use case for maximum success.'
                  }
                />
              </Link>
            </Box>

            <Box {...col}>
              <Link to={`/miscellaneous/6-Must-Have-Benefits-of-AI-Automation`}>
                <Image
                  src={benifits}
                  alt="6-Must-Have-Benefits-of-AI-Automation"
                />
                {/* <Heading
                {...title}
                content="Ultimate 4 Step Guide to Identify IPA use Cases"
              /> */}
                <Heading
                  {...description}
                  content={'6 Must Have Benefits of AI Automation'}
                />
              </Link>
            </Box>
          </Box>
        </Container>
      </Fade>
    </MiscWrapper>
  );
};

MiscellaneousSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  textArea: PropTypes.object,
  description: PropTypes.object,
};

MiscellaneousSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    // justifyContent: 'flex-start',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    width: ['100%', '100%', '47%', '30%', '30%'],
    margin: '2% 1%',
    padding: '30px 15px 15px 15px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    className: 'misc_contianer',
  },
  textArea: {
    margin: '20px 15px',
    padding: '30px 15px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    className: 'misc_contianer',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '5px',
    pt: '15px',
    textAlign: 'center',
  },
  description: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    color: '#252525',
    mb: '0px',
    pt: '15px',
    textAlign: 'center',
  },
};

export default MiscellaneousSection;
